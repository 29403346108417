import React, { useContext, useEffect, useMemo, useState } from "react";
import { PacoteConsumoContext } from "../../../../context/PacoteConsumoContext";
import PacoteConsumoService from "../../../../services/PacoteConsumoService";
import formatDommus from "../../../../helpers/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

const meiosValidos = ['email', 'sms', 'voip', 'whatsapp-conversas', 'whatsapp-templates'];

export function CardPacote({titulo, subtitulo=null, meio, unidadeConsumo}){
    const {canalAtivo, setCanalAtivo, valoresPacotesConsumo, setValoresPacotesConsumo, mesReferencia } = useContext(PacoteConsumoContext);
    const [carregando, setCarregando] = useState(true);

    useEffect(()=>{
        const filtros = {
          mes_referencia: mesReferencia
        }
        setCarregando(true);
        if(meiosValidos.includes(meio)){
            PacoteConsumoService.buscarConsumoPorModuloPorMeio(6, meio, filtros).then((response)=>{
                setValoresPacotesConsumo((current) => {return {...current, ...{[meio]: response?.data}}})
                setTimeout(()=>{
                  setCarregando(false)
                }, 300)
            }).catch((error)=>{
                setCarregando(false);
            })
        }
    },[mesReferencia])

    const classeConsumo = useMemo(()=>{
        if(!valoresPacotesConsumo[meio]?.capacidade_pacote || valoresPacotesConsumo[meio]?.capacidade_pacote === 0){
            return 'normal'
        }else if(valoresPacotesConsumo[meio]?.total_mes > valoresPacotesConsumo[meio]?.capacidade_pacote){
            return 'excedente';
        }else if(valoresPacotesConsumo[meio]?.total_mes === valoresPacotesConsumo[meio]?.capacidade_pacote){
            return 'limite';
        }else{
            return 'abaixo';
        }
    },[valoresPacotesConsumo])

    return (
        <div 
            className={`card-section ${canalAtivo === meio ? 'ativo' : ''}`}
            onClick={() => {
                if(!carregando){
                    setCanalAtivo((current) => meio === current ? null : meio)
                }
            }}
            style={{cursor: carregando ? 'not-allowed' : 'pointer'}}
        >
        {valoresPacotesConsumo[meio] && valoresPacotesConsumo[meio]?.valor_total ?
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="fechar-biblioteca-graficos" className="tooltip-info">
                <div><b>Nome:</b> {valoresPacotesConsumo[meio]?.nome}</div>
                <div><b>Início:</b> {moment(valoresPacotesConsumo[meio]?.data_inicio).format('DD/MM/YYYY')}</div>
                <div><b>Término:</b> {moment(valoresPacotesConsumo[meio]?.data_termino).format('DD/MM/YYYY')}</div>
                <div><b>Capacidade:</b> {valoresPacotesConsumo[meio]?.capacidade_pacote}</div>
                <div><b>Valor Pacote:</b> {formatDommus.formatarReal(valoresPacotesConsumo[meio]?.valor_pacote ?? "")}</div>
              </Tooltip>
          }>
            <span className="info-icon"><FontAwesomeIcon icon={faInfoCircle}/></span>
          </OverlayTrigger>
        : <></>}
            <div className="title-section">
              <span className="title-card">{titulo}</span>
              <span className="subtitle-card">{subtitulo}</span>
            </div>
            {carregando ? 
                <div className="skeleton-card-pacote">
                    <div className="skeleton-valor skeleton-animation"/>
                    <div className="skeleton-consumo-section">
                        <div className="skeleton-consumo skeleton-animation"></div> 
                        <div className="skeleton-consumo skeleton-animation"></div> 
                    </div>
                </div>
                : ((valoresPacotesConsumo[meio] ?? false) ?
                <>
                    <span className="valor-card">{formatDommus.formatarReal(valoresPacotesConsumo[meio]?.valor_total ?? 0)}</span>
                    <div className="consumo-section">
                      <span className="title-consumo">Consumo</span>
                      <span className={`valor-consumo ${classeConsumo}`}>{unidadeConsumo}: {valoresPacotesConsumo[meio]?.total_mes}{meio === 'voip' ? " min" : valoresPacotesConsumo[meio]?.capacidade_pacote ? `/${valoresPacotesConsumo[meio]?.capacidade_pacote}` : ""}</span>
                    </div>
                </>
                : 
                  <span className="info-card">Pacote não encontrado</span>
                )
            }
        </div>
    )
}
